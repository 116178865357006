<template>
    <div class="awards-slider innerContainer">
        <h2 class="header-title luxury">{{ title }}</h2>
        <div class="swiper-container awards-swiper">
            <div class="swiper-wrapper ">
                <div v-for="(item,index) in slides" :key="index" class="swiper-slide">
                    <div class="image-inner">
                        <div class="logo" v-html="item.logo"></div>
                        <div class="image-hover-container">
                            <picture>
                                <source
                                    media="(max-width:767px)"
                                    :srcset="item.mobile"
                                />
                                <source
                                    media="(max-width:1023px)"
                                    :srcset="item.tablet"
                                />
                                <img class="slide-image"
                                     :src="item.desktop"
                                />
                            </picture>
                        </div>
                    </div>
                    <span class="date luxury">{{ item.title }}</span>
                    <span class="slide-award-title fira">{{ item.teaser }}</span>
                    <span class="description fira" v-html="item.content"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import Swiper from "swiper";

const props = defineProps({
    contentData: {
        type: Object,
    },
});
let title = computed(() => props.contentData?.conf?.header?.title)
let slides = computed(() => props.contentData.data?.list?.map(item => ({
    ...item,
    mobile: item?.images?.[0]?.devices?.mobile,
    desktop: item?.images?.[0]?.devices?.desktop,
    tablet: item?.images?.[0]?.devices?.tablet,
    logo: item.logo?.logo
})))
let swiper = ref(null)

const setSwiper = () => {
    swiper = new Swiper('.awards-swiper', {
        spaceBetween: 21,
        speed: 1200,
        grabCursor: true,
        slidesPerView: 1.2,
        breakpoints: {
            767: {
                slidesPerView: 2.5,
            },
            1365: {
                slidesPerView: 3.4,
            },
        },
    });
}

onMounted(() => {
    setSwiper()
})
</script>


<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);
.awards-slider {
    color: $primaryNavy;
    margin-top: 89px;
    padding-bottom: 77px;

    .header-title {
        font-weight: 400;
        font-size: 55px;
        line-height: 52px;
        text-transform: uppercase;
        transition: all .45s $ease;
        @media only screen and (max-width: 1440px) {
            font-size: 34px;
        }
    }

    .swiper-container {
        margin-top: 55px;
        @media only screen and (max-width: 1023px) {
            margin-top: 34px;
        }

        .swiper-slide {
            .image-inner {
                width: 100%;
                position: relative;

                .logo {
                    position: absolute;
                    right: 50px;
                    z-index: 2;
                    @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                        right: 30px;
                    }

                    @media only screen and (max-width: 1023px) {
                        right: 20px;
                    }
                }

                .image-hover-container {
                    overflow: hidden;

                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }

                    img {
                        height: 377px;
                        width: 100%;
                        object-fit: cover;
                        transition: all .45s cubic-bezier(.23, -0.01, .03, 1);
                        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                            height: 300px;
                        }
                        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                            height: 300px;
                        }
                        @media only screen and (max-width: 1023px) {
                            height: 250px;
                        }

                    }
                }
            }


            span {
                display: block;
                transition: all .45s $ease;
            }

            .date {
                margin-top: 21px;
                font-weight: 400;
                font-size: 34px;
                text-transform: uppercase;

            }

            .slide-award-title {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
            }

            .description {
                margin-top: 13px;
                font-weight: 500;
                font-size: 21px;
                line-height: 25px;
                @media only screen and (max-width: 1439px) {
                    font-size: 18px;
                }
            }
        }
    }

}
</style>
<style lang="scss">
body {
    &.is-dark {
        .awards-slider {
            color: white;
        }
    }
}

</style>
